import { render, staticRenderFns } from "./add-basic.vue?vue&type=template&id=7b4b0ca5&scoped=true&"
import script from "./add-basic.vue?vue&type=script&lang=ts&"
export * from "./add-basic.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b4b0ca5",
  null
  
)

export default component.exports