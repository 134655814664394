













































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AddLogistics extends Vue {
    @Prop() value: any
    @Prop({ default: () => ({}) }) lists: any
    get freightList() {
        return this.lists.freight_list || []
    }
}
