








































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AddBasic extends Vue {
    @Prop() value: any
    @Prop({ default: () => ({}) }) lists: any
    addVideo = false
    form = {}
    fileList = []
    @Watch('value.video', { immediate: true })
    videoChange(val: string) {
        if (val) {
            this.addVideo = true
        }
    }
    get categoryList() {
        return this.lists.category_list || []
    }

    get brandList() {
        return this.lists.brand_list || []
    }

    get unitList() {
        return this.lists.unit_list || []
    }

    get supplierList() {
        return this.lists.supplier_list || []
    }

    created() {}
}
